import(/* webpackMode: "eager", webpackExports: ["Badge"] */ "/codebuild/output/src287774734/src/craft-stadium-client/node_modules/@nextui-org/badge/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/codebuild/output/src287774734/src/craft-stadium-client/node_modules/@nextui-org/button/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Chip"] */ "/codebuild/output/src287774734/src/craft-stadium-client/node_modules/@nextui-org/chip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src287774734/src/craft-stadium-client/node_modules/@nextui-org/image/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src287774734/src/craft-stadium-client/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src287774734/src/craft-stadium-client/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["MailMagazine"] */ "/codebuild/output/src287774734/src/craft-stadium-client/src/app/(service)/raid/_components/MailMagazine.tsx");
